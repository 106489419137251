import axios from "axios";

const ACCESS_TOKEN = "";

const api = axios.create({
  baseURL: "https://jumpapp.co.kr/api",
  headers: { Authorization: "FineAdmin localhost" },
});

const kakaoApi = axios.create({
  baseURL: "https://kapi.kakao.com/v2/api",
  headers: { Authorization: `Bearer ${ACCESS_TOKEN}` },
});

export const getNews = () => {
  return api.get("/news?skipPage=1&limit=50");
};

export const sendKakao = () => {
  return kakaoApi.post("/talk/memo/scrap/send");
};

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, ButtonGroup } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import { getNews } from "./api";
// import { data } from "./sample";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 80vh;
`;

const columns = [
  { field: "id", headerName: "id", width: 100 },
  { field: "title", headerName: "제목", width: 300 },
  { field: "url", headerName: "링크", width: 150 },
  {
    field: "origin",
    headerName: "출처",
    width: 130,
  },
  {
    field: "keyword",
    headerName: "키워드",
    width: 130,
  },
  {
    field: "imgSrc",
    headerName: "imgSrc",
    width: 130,
  },
  {
    field: "createdAt",
    headerName: "생성시간",
    width: 130,
  },
];

const getKakao = () => {
  if (!window.Kakao) {
    return null;
  }

  const kakao = window.Kakao;
  if (!kakao.isInitialized()) {
    kakao.init("7b6d395177dc5cfc799d074c2761a42b");
  }

  return kakao;
};

const loginWithKakao = () => {
  const Kakao = getKakao();

  if (Kakao) {
    Kakao.Auth.login({
      success: function (authObj) {
        // alert(JSON.stringify(authObj));
        alert("로그인완료");
        window.location.reload();
      },
      fail: function (err) {
        alert(JSON.stringify(err));
      },
    });
  }
};

const kakaoLogout = () => {
  const Kakao = getKakao();

  if (!Kakao.Auth.getAccessToken()) {
    alert("아직 로그인 안했는뎁쇼?");
    return;
  }
  Kakao.Auth.logout(function () {
    // alert("logout ok\naccess token -> " + Kakao.Auth.getAccessToken());
    alert("로그아웃 성공");
    window.location.reload();
  });
};

const loginFormWithKakao = () => {
  if (window.Kakao) {
    const kakao = window.Kakao;

    if (!kakao.isInitialized()) {
      console.log("not initialized");
      kakao.init("7b6d395177dc5cfc799d074c2761a42b");
    }

    kakao.Auth.loginForm({
      success: function (authObj) {
        console.log(JSON.stringify(authObj));
        showResult(JSON.stringify(authObj));
      },
      fail: function (err) {
        showResult(JSON.stringify(err));
      },
    });
  }
};

function showResult(result) {
  document.getElementById("reauthenticate-popup-result").innerText = result;
}

function sendTo(selectContent) {
  const Kakao = getKakao();

  Kakao.Auth.login({
    scope: "PROFILE,TALK_MESSAGE",
    success: function () {
      Kakao.API.request({
        url: "/v2/api/talk/memo/default/send",
        data: {
          template_object: {
            object_type: "list",
            header_title: "JUMP DAILY MAGAZINE",
            header_link: {
              mobile_web_url: "https://developers.kakao.com",
              web_url: "https://developers.kakao.com",
            },
            contents: selectContent,
            buttons: [],
          },
        },
        success: function (res) {
          alert("success: " + JSON.stringify(res));
        },
        fail: function (err) {
          alert("error: " + JSON.stringify(err));
        },
      });
    },
    fail: function (err) {
      alert("failed to login: " + JSON.stringify(err));
    },
  });
}

const App = () => {
  const [data, setData] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const Kakao = getKakao();
  const tableRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { results },
      } = await getNews();
      setData(results);
    };

    fetchData();
  }, []);

  const selectedContent = () => {
    const content = selectedRow.map((item) => {
      return {
        title: item.title,
        description: item.keyword,
        image_url: item.imgSrc,
        link: {
          mobile_web_url: item.url,
          web_url: item.url,
        },
      };
    });

    return content;
  };

  const sendContent = selectedContent();

  return (
    <Container>
      <DataGrid
        ref={tableRef}
        rows={data}
        columns={columns}
        checkboxSelection
        autoPageSize
        onRowSelected={(newSelection) => {
          setSelectedRow([...selectedRow, newSelection.data]);
        }}
        onSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection.selectionModel);
          if (newSelection.selectionModel.length > 5)
            alert("5개까지만 선택하세요.");
        }}
        selectionModel={selectionModel}
      />

      {Kakao.Auth.getAccessToken() ? (
        <ButtonGroup size="large" color="primary">
          <Button variant="outlined" color="primary" onClick={kakaoLogout}>
            로그아웃
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => sendTo(sendContent)}
          >
            나에게 보내기
          </Button>
        </ButtonGroup>
      ) : (
        <Button variant="outlined" color="primary" onClick={loginWithKakao}>
          카카오 계정으로 로그인
        </Button>
      )}
    </Container>
  );
};

export default App;
